
















import { Component, Vue, Prop } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import SitesServices from "@/services/SitesServices";

import table from "@/utils/table";

@Component({
  components: {
    Modal: () => import("@/components/Shared/Modal.vue"),
  },
})
export default class ComplementsCoutsSuppField extends BaseField {
  private isSelectAllClicked: boolean = false;
  private selectedOperationFamilies: Array<Object> = [];
  private isModalVisible: boolean = false;
  private vue: any = this.$parent.$parent.$parent;
  /**
   * #######
   * Methods
   * #######
   */
  private getComplementTranslationByKey(key: string) {
    return this.$t(`ComplementsCoutsSuppAdministration.${key}`).toString();
  }
  private get typeTravauxId():string{
    return this.props.rowData.typeTravauxId;
  }
  private showModal() {
    if (this.canBeEdited) this.vue.openModal(this.props);
  }
}
