








import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseField from '@/components/Shared/Table/BaseField.vue';
import { BopStatus } from '@/utils/Enums';
import router from '@/router';

@Component({
  components: {},
})
export default class ActionBopAccueilField extends BaseField {
  @Prop({ required: true }) public props!: any;
  private isAction: boolean = false;
  private key: string = '';
  mounted() {
    this.key = this.getByProperty('bopStatusKey');
    switch (this.key) {
      case BopStatus[BopStatus.Initial]:
        break;
      case BopStatus[BopStatus.Simulation]:
        break;
      case BopStatus[BopStatus.Completed]:
        break;
      case BopStatus[BopStatus.WaitingForValidation]:
        break;
      case BopStatus[BopStatus.AcceptLunchWork]:
        break;
      case BopStatus[BopStatus.Finished]:
        this.isAction = true;
        break;
      case BopStatus[BopStatus.OkForReception]:
        break;
      case BopStatus[BopStatus.Archived]:
        this.isAction = true;
        break;
      case BopStatus[BopStatus.Declined]:
        break;
      default:
        break;
    }
  }

  private action() {
    this.$store.commit('changeBop', this.props.rowData);
    router.push({
      name: 'BopSetupUpdate',
      params: { bopId: this.props.rowData.id },
      query: { index: '5' },
    });
  }
}
