














import { Component, Vue, Prop } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";

@Component({
  components: {},
})
export default class SwitchField extends BaseField {
  public switchM() {
    if (this.canBeEdited) this.rawData = !this.rawData;
    if(!this.rawData) {
      if (this.isNatureTacheMontageField ) {
        this.props.rowData.secondValue= 0;
      }else if (this.isNatureTacheDemontageField){
        this.props.rowData.value  = 0;
      }
  }
  }
  private get isNatureTacheMontageField(): boolean {
    return this.props.rowField.isNatureTacheField && this.props.rowField.isMontage;
  }
  private get isNatureTacheDemontageField(): boolean {
    return this.props.rowField.isNatureTacheField && !this.props.rowField.isMontage ;
  }
}
