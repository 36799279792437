import {ExportDto} from "@/services/ExportService";
import ExportBopModelHelper from "@/utils/ExportHelper";
import Vue from "vue";
import {Store} from "vuex";
import {AxiosInstance} from "axios";
import table from "@/utils/table";
import lodash from "lodash";

export default class TableApiHelper {
    private readonly _store:Store<any>;
    private readonly _api:AxiosInstance;
    private get canShowExportLoader():boolean{
        return this._store.state.showExportLoader;
    }
    private set canShowExportLoader(value:boolean){
        this._store.commit('changeShowExportLoader',value);
    }
    public findByPath(element:any,path:string):any{
        return table.findByPath(element,path);
    }
    public async exportData(exportUrl:string){
        try {
            this.canShowExportLoader = true;
            const exportDto:ExportDto = (await this._api.get(exportUrl)).data;
            ExportBopModelHelper.exportData(exportDto);
        }catch (ex){
            console.error(ex);
        }finally {
            this.canShowExportLoader = false;
        }

    }
    public getValuesFromTrackId(vueTableData:Array<any>,bulkActionsSelectedRows:Array<string>,bulkTrackBy:string):Array<object>{
        const dataTableMap:Map<string,any> = new Map<string, any>();
        vueTableData.forEach(rowData =>{
            const trackId:string = this.findByPath(rowData,bulkTrackBy);
            dataTableMap.set(trackId, lodash.cloneDeep(rowData));
        });
        return bulkActionsSelectedRows.map(trackId => dataTableMap.get(trackId));
    }
    constructor(store,api) {
        this._api = api;
        this._store = store;
    }

}