















import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import table from "@/utils/table";
import { BopModelEnum } from "@/utils/Enums";

const TYPE_ISOLATION_COLUMN = "TypeIsolation";
const NATURE_APPAREIl_COLUMN = "NatureAppareil";

@Component({
  components: {},
})
export default class CoeffOptionDropdownField extends BaseField {
  private isBeingEdited: Boolean = false;
  public data: Array<any> = [];
  public selectedText: string = "";
  public init: boolean = true;
  public isDisabled: boolean = false;
  private  selectedValue:string ="";


  private get displayData(): any {
    return (
      table.findByPath(this.props.rowData, this.props.rowField.display) ?? ""
    );
  }
  private get valueField(): any {
    return this.props.rowField.valueField ?? "";
  }
  private  get trackId(){
    return this.props.rowData.trackId;
  }
  private get textField(): any {
    return this.props.rowField.textField;
  }
  private getSelectedValue(coefficientOptionId): string {
    let result = "";
    if (coefficientOptionId) {
      let arrResult = this.data.find(
        (d) => table.findByPath(d, this.valueField) === coefficientOptionId
      );
      result =
        arrResult === undefined
          ? ""
          : table.findByPath(arrResult, this.textField);
    }
    return result;
  }
  private get coeffKey() {
    return this.props.rowField.coeffKey;
  }
  public edit(newId: any, eventType): void {
    this.init = false;
    if(this.canBeEdited) {
      if (typeof newId == "string") {
        const newElement = this.data.find((el) => el.id === newId);
        if(!this.props.rowData.coefficients[this.props.rowField.coeffKey]){
          this.props.rowData.coefficients[this.props.rowField.coeffKey] = {
            coefficientId: this.props.rowField.options[0].coefficientId
          }
        }
        this.props.rowData.coefficients[this.props.rowField.coeffKey].flagAction =
          newElement.flagAction;
        this.selectedValue= this.getSelectedValue(newId);
        this.rawData = newId;
        // this.isNew = false;
      }
      if (this.props.rowData.coefficients[NATURE_APPAREIl_COLUMN]) {
        const isNatureAppareilFlagged = !this.props.rowData.coefficients[
          NATURE_APPAREIl_COLUMN
        ].flagAction;
        this.isDisabled =
          this.coeffKey === TYPE_ISOLATION_COLUMN && isNatureAppareilFlagged 
        if (isNatureAppareilFlagged) {
          if (this.props.rowData.coefficients[TYPE_ISOLATION_COLUMN])
            this.props.rowData.coefficients[
              TYPE_ISOLATION_COLUMN
            ].coefficientOptionId = null;
        }
      }
      if (eventType === "change") {
        this.isBeingEdited = !this.isBeingEdited;
        this.$store.commit('changeHasBeenModified',true);
      }
      if (!this.props.rowField.isNotEditable && !this.isBeingEdited) {
        //remove isBeingEdited if dblclick
        if (eventType === "click") {
          // this.isNew = true;
          this.isBeingEdited = !this.isBeingEdited;
        }
      }
    }
  }
  public created() {
    this.data = this.props.rowField.options;
    // this.isNew = this.rawData === undefined || this.rawData === null;
    this.selectedValue= this.getSelectedValue(this.rawData);
    if(this.trackId){
      this.$watch('trackId', ()=> this.selectedValue= this.getSelectedValue(this.rawData));
    }
  }
  @Watch('props.rowData')
  onRowDataCHanged(){
    if(!this.rawData)
      this.selectedValue ="";
  }



}
