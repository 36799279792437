





















import { Component, Vue, Prop } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import SitesServices from "@/services/SitesServices";

import table from "@/utils/table";

@Component({
  components: {},
})
export default class SitesTypeTravauxField extends BaseField {
  private sites: Array<any> = [];
  private vue: any = this.$parent.$parent.$parent;
  get rootFieldId(): any {
    return table.findByPath(this.props.rowData, this.props.rowField.rootId);
  }
  public async mounted() {

  }

  public editCategories(event: Event, props: any): void {
    if(this.canBeEdited) this.vue.openModal(props);
  }

  public deleteCategory(index: number): void {
    (<Array<String>>this.rawData).splice(index, 1);
    (<Array<String>>this.sites).splice(index, 1);

    this.vue.$refs.table.modify(this.props.rowData);
  }

  private display(category: any): string {
    return table.findByPath(category, this.props.rowField.display);
  }
}
