
















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import table from "@/utils/table";

@Component({
  components: {},
})
export default class DropdownField extends BaseField {
  private isBeingEdited: Boolean = false;
  private selectedText: string = "";
  private init: boolean = true;
  private get displayData(): any {
    return table.findByPath(this.props.rowData, this.props.rowField.display);
  }

  private get valueField(): any {
    return this.props.rowField.valueField;
  }

  private get textField(): any {
    return this.props.rowField.textField;
  }
  private get options() {
    return this.props.rowField.options;
  }
  private get selectedValue(): string {
    try {
      let result = "";
      if (this.init && this.displayData) {
        // console.log("Im here not supposed to be here tho :/")
        result = this.displayData;
      } else {
        let arrResult = this.props.rowField.options.find(
          (d: any) => table.findByPath(d, this.valueField) === this.rawData
        );
        result =
          arrResult === undefined
            ? ""
            : table.findByPath(arrResult, this.textField);
      }
      return result;
    } catch {
      return "";
    }
  }
  private get dataContainer() {
    return this.props.rowField.dataContainer;
  }
  private get computedClass() {
    let res = "";
    if (this.selectedValue && this.selectedValue.length > 100) res += " larger";
    return res;
  }

  /**
   * Methods
   */
  private onBlur() {
    // console.log("blured");
  }
  private edit(eventType): void {
    if(this.canBeEdited) {
      this.init = false;
      // console.log(eventType);
      if (eventType === "change") {
        this.isBeingEdited = !this.isBeingEdited;
      }
      if (!this.props.rowField.isNotEditable && !this.isBeingEdited) {
        //remove isBeingEdited if dblclick
        if (eventType === "click") {
          this.isBeingEdited = !this.isBeingEdited;
        }
        if (!this.isBeingEdited) {
          (<any>this.$parent).reload();
        }
      }
      this.$emit("dropDownChange", this.props);
    }
  }

  /**
   * hooks
   */
  public beforeMount() {
    if (
      Number.isInteger(this.props.rowField.default) &&
      !this.rawData &&
      this.props.rowField.options.length > this.props.rowField.default
    ) {
      this.rawData = table.findByPath(
        this.props.rowField.options[this.props.rowField.default],
        this.valueField
      );
    }
    if (this.dataContainer) {
      const opt = this.options.find((o: any) => o.key.id == this.rawData);
      if (!opt) return;
      table.setByPath(
        this.props.rowData,
        this.props.rowField.dataContainer,
        JSON.parse(JSON.stringify(opt.key))
      );
    }
  }

  /**
   * Watchers
   */
  @Watch("rawData")
  private updateSideProperties(newValue: String) {
    if (
      this.props.rowField.sideProperties &&
      this.props.rowField.sideProperties.length > 0
    ) {
      const updatedObject = this.props.rowField.options.find(
        (d: any) => table.findByPath(d, this.valueField) === this.rawData
      );
      this.props.rowField.sideProperties.forEach((prop) => {
        const newData = table.findByPath(updatedObject, prop.from);
        table.setByPath(this.props.rowData, prop.to, newData);
      });
    }
    if (this.dataContainer) {
      const opt = this.options.find((o: any) => o.key.id == this.rawData);
      if (!opt) return;
      table.setByPath(
        this.props.rowData,
        this.props.rowField.dataContainer,
        JSON.parse(JSON.stringify(opt.key))
      );
    }
  }
}
