







import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseField from '@/components/Shared/Table/BaseField.vue';

@Component({
    components: {
    },
})
export default class DetailField extends BaseField {
    public toggleDetail(event: Event, props: any): void {
        if(this.canBeEdited) {
            (<any>this.$parent).toggleDetailRow(props.rowData.id);
            if(event.target) (<HTMLBaseElement>event.target).classList.toggle("up");
        }
    }
}
