






import { Component, Vue, Prop } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import SitesServices from "@/services/SitesServices";
import {OperationType} from '@/utils/Enums'
import table from "@/utils/table";

@Component({
  components: {},
})
export default class OperationTypeField extends BaseField {
  private sites: Array<any> = [];
  private vue: any = this.$parent.$parent.$parent;
  private get display(): any {
    const value = table.findByPath(this.props.rowData, this.props.rowField.display);
   
     return value ? value : this.mainOption ;
  }
  private get mainOption(): string {
    const operationType = this.props.rowData.operationType;
    let mainOption = this.$t("bopModelSetup.OperationDiverse").toString();
    if (operationType == OperationType.OperationPrincipal) {
      mainOption = this.$t("bopModelSetup.OperationPrincipale").toString();
    }
    return mainOption;
  }
  public async mounted() {}

  public editCategories(event: Event, props: any): void {
    if(this.canBeEdited) this.vue.openOperationTypesModal(props);
  }



  
}
