
















import { Component, Vue, Prop } from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import SitesServices from "@/services/SitesServices";

import table from "@/utils/table";

@Component({
  components: {
    Modal: () => import("@/components/Shared/Modal.vue"),
  },
})
export default class OperationFamiliesField extends BaseField {
  private isSelectAllClicked: boolean = false;
  private selectedOperationFamilies: Array<Object> = [];
  private isModalVisible: boolean = false;
  private vue: any = this.$parent.$parent.$parent;
  private get operationFamilies() {
    return this.props.rowField.operationFamilies;
  }
  /**
   * #######
   * Methods
   * #######
   */
  private showModal() {
    // console.log('SHOW THE FUCKIBNG MODAL')
    if (this.canBeEdited) this.vue.openModal(this.props);
  }
  

  /**
   * ######
   * Hooks
   * ######
   */
  public async created() {}

  // public editCategories(event: Event, props: any): void {
  //   if (this.canBeEdited) this.vue.openModal(props);
  // }

  // public deleteCategory(index: number): void {
  //   (<Array<String>>this.rawData).splice(index, 1);
  //   (<Array<String>>this.sites).splice(index, 1);

  //   this.vue.$refs.table.modify(this.props.rowData);
  // }

  // private display(category: any): string {
  //   return table.findByPath(category, this.props.rowField.display);
  // }
}
